
import { MoonPayProvider } from '@moonpay/moonpay-react';
import { MoonPayBuyWidget } from '@moonpay/moonpay-react';
import './App.css';
import { useState } from 'react';

function App() {
  const [visible, setVisible] = useState(false);
  return (
    <MoonPayProvider 
    apiKey="pk_test_YPnQuq6EYpEq4lFiNj6vqxv9r3na3Uw2" 
    environment="sandbox" 
    debug
>
    
    <MoonPayBuyWidget
            variant="embedded"
            baseCurrencyCode="usd"
            baseCurrencyAmount="100"
            defaultCurrencyCode="eth"
            visible={visible}
        />
        <button onClick={() => setVisible(!visible)}>
            Toggle widget
        </button>
</MoonPayProvider>
  );
}

export default App;
